import React from "react";

export default function TermsOfUse() {
  return (
    <div id="termsofuse" className="px-5 my-6">
      <h1>
        <strong>SwyftDoc Terms of Use</strong>
      </h1>
      <p class="last-revision">Last revision: July 1, 2019</p>

      <h2>Use of the Site.</h2>
      <p>
        Swyft Health, LLC. (“<strong>SwyftDoc</strong>”, “<strong>we</strong>”,
        “<strong>us</strong>”, or “<strong>our</strong>”) operates the website
        located at{" "}
        <strong>
          <a href="http://www.swyftdoc.com" target="_blank">
            www.swyftdoc.com
          </a>
        </strong>{" "}
        and other related websites and mobile applications with links to these
        Terms of Use (collectively, the “<strong>Site</strong>”). We offer
        online telehealth services enabling our members (“
        <strong>Members</strong>”) to report their health history and engage
        healthcare professionals (“<strong>Healthcare Professionals</strong>”)
        to obtain medical and healthcare services (“<strong>Services</strong>”).
        By accessing and using the Site, you agree to be bound by these Terms of
        Use and all other terms and policies that appear on the Site. If you do
        not wish to be bound by any of these Terms of Use, you may not use the
        Site or the Services.
      </p>

      <h2>Healthcare Services.</h2>
      <p>
        The Healthcare Professionals who deliver Services through SwyftDoc are
        independent professionals practicing within a group of independently
        owned professional practices collectively known as “
        <strong>SwyftDoc Professionals</strong>”. Swyft Health, LLC. does not
        practice medicine or any other licensed profession, and does not
        interfere with the practice of medicine or any other licensed profession
        by Healthcare Professionals, each of whom is responsible for his or her
        services and compliance with the requirements applicable to his or her
        profession and license. Neither SwyftDoc nor any third parties who
        promote the Services or provide you with a link to the Services shall be
        liable for any professional advice you obtain from a Healthcare
        Professional via the Services.
      </p>

      <h2>Site Content.</h2>
      <p>
        None of the Site content (other than information you receive from
        Healthcare Professionals) should be considered medical advice or an
        endorsement, representation or warranty that any particular medication
        or treatment is safe, appropriate, or effective for you.{" "}
      </p>

      <h2>User Accounts.</h2>
      <p>
        When you register on the Site, you are required to create an account (“
        <strong>Account</strong>”) by entering your name, email address,
        password and certain other information collected by SwyftDoc
        (collectively “<strong>Account Information</strong>”). To create an
        Account, you must be of legal age to form a binding contract. If you are
        not of legal age to form a binding contract, you may not register to use
        our Services. You agree that the Account Information that you provide to
        us at all times, including during registration and in any information
        you upload to the Site, will be true, accurate, current, and complete.
        You may not transfer or share your Account password with anyone, or
        create more than one Account (with the exception of subaccounts
        established for children of whom you are the parent or legal guardian).
        You are responsible for maintaining the confidentiality of your Account
        password and for all activities that occur under your Account. SwyftDoc
        reserves the right to take any and all action, as it deems necessary or
        reasonable, regarding the security of the Site and your Account
        Information. In no event and under no circumstances shall SwyftDoc be
        held liable to you for any liabilities or damages resulting from or
        arising out of your use of the Site, your use of the Account Information
        or your release of the Account Information to a third party. You may not
        use anyone else's account at any time.
      </p>

      <h2>Use of the Services by Children.</h2>
      <p>
        The Services are available for use by children but children are not
        authorized to become Members and may not use the Services without the
        supervision of a parent or legal guardian. If you register as the parent
        or legal guardian on behalf of a minor, you will be fully responsible
        for complying with these Terms of Use.
      </p>

      <h2>Access Rights.</h2>
      <p>
        We hereby grant to you a limited, non-exclusive, nontransferable right
        to access the Site and use the Services solely for your personal
        non-commercial use and only as permitted under these Terms of Use and
        any separate agreements you may have entered into with us (“
        <strong>Access Rights</strong>”). We reserve the right, in our sole
        discretion, to deny or suspend use of the Site or Services to anyone for
        any reason. You agree that you will not, and will not attempt to: (a)
        impersonate any person or entity, or otherwise misrepresent your
        affiliation with a person or entity; (b) use the Site or Services to
        violate any local, state, national or international law; (c) reverse
        engineer, disassemble, decompile, or translate any software or other
        components of the Site or Services; (d) distribute viruses or other
        harmful computer code through the Site; or (e) otherwise use the
        Services or Site in any manner that exceeds the scope of use granted
        above. In addition, you agree to refrain from abusive language and
        behavior which could be regarded as inappropriate, or conduct that is
        unlawful or illegal, when communicating with Healthcare Professionals
        through the Site and to refrain from contacting Healthcare Professionals
        for telehealth services outside of the Site. SwyftDoc is not responsible
        for any interactions with Healthcare Professionals that are not
        conducted through the Site. We strongly recommend that you do not use
        the Services on public computers. We also recommend that you do not
        store your Account password through your web browser or other software.
      </p>

      <h2>Fees and Purchase Terms.</h2>
      <p>
        You agree to pay all fees or charges to your Account in accordance with
        the fees, charges, and billing terms in effect at the time a fee or
        charge is due and payable. By providing SwyftDoc with your credit card
        number or PayPal or Venmo account and associated payment information,
        you agree that SwyftDoc is authorized to immediately invoice your
        account for all fees and charges due and payable to SwyftDoc hereunder
        and that no additional notice or consent is required. If your health
        plan, employer or agency has arranged with SwyftDoc to pay the fee or
        any portion of the fee, or if the fee is pursuant to some other
        arrangement with SwyftDoc, that fee adjustment will be reflected in the
        fee that you are ultimately charged. Please check with your employer,
        health plan or agency to determine if any Services will be reimbursed.{" "}
      </p>
      <p>
        <strong>
          If you do not have insurance coverage for Services, or if your
          coverage is denied, you acknowledge and agree that you shall be
          personally responsible for all incurred expenses.
        </strong>{" "}
        SwyftDoc offers no guarantee that you shall receive any such
        reimbursement. SwyftDoc reserves the right to modify or implement a new
        pricing structure at any time prior to billing you for your initial
        payment or for future payments due pursuant to these Terms of Use.{" "}
        <strong>
          You understand and agree that for Services provided on an appointment
          basis, you will be responsible for a missed appointment fee equal to
          all or a portion of the fees you and your insurer or other payor would
          have paid for the scheduled services if you do not cancel a scheduled
          appointment at least 24 hours in advance, unless we notify you in
          writing that a shorter cancellation window applies.
        </strong>
      </p>

      <h2>Termination.</h2>
      <p>
        You may deactivate your Account and end your registration at any time,
        for any reason by sending an email to{" "}
        <a href="mailto:support@swyftdoc.com" target="_blank">
          support@swyftdoc.com
        </a>
        . SwyftDoc may suspend or terminate your use of the Site, your Account
        and/or registration for any reason at any time. Subject to applicable
        law, SwyftDoc reserves the right to maintain, delete or destroy all
        communications and materials posted or uploaded to the Site pursuant to
        its internal record retention and/or content destruction policies. After
        such termination, SwyftDoc will have no further obligation to provide
        the Services, except to the extent we are obligated to provide you
        access to your health records or Healthcare Professionals are required
        to provide you with continuing care under their applicable legal,
        ethical and professional obligations to you.
      </p>

      <h2>Right to Modify.</h2>
      <p>
        We may at our sole discretion change, add, or delete portions of these
        Terms of Use at any time on a going-forward basis. Continued use of the
        Site and/or Services following notice of any such changes will indicate
        your acknowledgement of such changes and agreement to be bound by the
        revised Terms of Use, inclusive of such changes.
      </p>

      <h2>DISCLAIMER OF WARRANTIES.</h2>
      <p>
        YOU EXPRESSLY AGREE THAT USE OF THE SITE OR SERVICES IS AT YOUR SOLE
        RISK. BOTH THE SITE AND SERVICES ARE PROVIDED ON AN "AS IS" AND "AS
        AVAILABLE" BASIS. SwyftDoc EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY
        KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO ANY
        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR PURPOSE,
        NON-INFRINGEMENT, TITLE, OPERABILITY, CONDITION, QUIET ENJOYMENT, VALUE,
        ACCURACY OF DATA AND SYSTEM INTEGRATION.
      </p>

      <h2>LIMITATION OF LIABILITY.</h2>
      <p>
        YOU UNDERSTAND THAT TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, IN NO
        EVENT WILL SwyftDoc, SwyftDoc PROFESSIONALS OR THEIR OFFICERS,
        EMPLOYEES, DIRECTORS, PARENTS, SUBSIDIARIES, AFFILIATES, AGENTS OR
        LICENSORS BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL
        OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF
        REVENUES, PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES
        ARISING OUT OF OR RELATED TO YOUR USE OF THE SITE OR THE SERVICES,
        REGARDLESS OF WHETHER SUCH DAMAGES ARE BASED ON CONTRACT, TORT
        (INCLUDING NEGLIGENCE AND STRICT LIABILITY), WARRANTY, STATUTE OR
        OTHERWISE.{" "}
      </p>
      <p>
        To the extent that we may not, as a matter of applicable law, disclaim
        any implied warranty or limit its liabilities, the scope and duration of
        such warranty and the extent of our liability will be the minimum
        permitted under such applicable law.
      </p>

      <h2>Indemnification.</h2>
      <p>
        You agree to indemnify, defend and hold harmless SwyftDoc, SwyftDoc
        Professionals and their officers, directors, employees, agents,
        subsidiaries, affiliates, licensors, and suppliers, harmless from and
        against any claim, actions, demands, liabilities and settlements,
        including without limitation reasonable legal and accounting fees (“
        <strong>Claims</strong>”), resulting from, or alleged to result from,
        your violation of these Terms of Use.
      </p>

      <h2>Geographical Restrictions.</h2>
      <p>
        SwyftDoc makes no representation that all products, services and/or
        material described on the Site, or the Services available through the
        Site, are appropriate or available for use in locations outside the
        United States or all states and territories within the United States.
      </p>

      <h2>Disclosures.</h2>
      <p>
        All Health Professionals performing licensed clinical services on the
        Site hold the professional licenses issued by the professional licensing
        boards or agencies in the states where they practice. All physicians and
        psychologists hold advanced degrees in either medicine or psychology and
        have undergone postgraduate training. You can report a complaint
        relating to the care provided by a Healthcare Professional by contacting
        the professional licensing board in the state where the care was
        received. In a professional relationship, sexual intimacy is never
        appropriate and should be reported to the board or agency that licenses,
        registers, or certifies the licensee.
      </p>
      <p>
        You can find the contact information for each of the state professional
        licensing boards governing medicine on the Federation of State Medical
        Boards website at
        <a
          href="http://www.fsmb.org/state-medical-boards/contacts"
          target="_blank"
        >
          http://www.fsmb.org/state-medical-boards/contacts
        </a>{" "}
        and governing psychology on the Association of State and Provincial
        Psychology Boards website at{" "}
        <a href="http://www.asppb.net/?page=BdContactNewPG" target="_blank">
          http://www.asppb.net/?page=BdContactNewPG
        </a>
        .
      </p>
      <p>
        Any clinical records created as a result of your use of the Services
        will be securely maintained by SwyftDoc on behalf of SwyftDoc
        Professionals for a period that is no less than the minimum number of
        years such records are required to be maintained under state and federal
        law, which is typically at least six years.
      </p>

      <h2>Website Links.</h2>
      <p>
        WE WILL NOT BE LIABLE FOR ANY INFORMATION, SOFTWARE, OR LINKS FOUND AT
        ANY OTHER WEBSITE, INTERNET LOCATION, OR SOURCE OF INFORMATION, NOR FOR
        YOUR USE OF SUCH INFORMATION, SOFTWARE OR LINKS, NOR FOR THE ACTS OR
        OMISSIONS OF ANY SUCH WEBSITES OR THEIR RESPECTIVE OPERATORS.
      </p>

      <h2>Ownership.</h2>
      <p>
        The Site and its entire contents, features and functionality (including
        but not limited to all information, software, text, displays, images,
        video and audio, and the design, selection and arrangement thereof), are
        owned by SwyftDoc, its licensors or other providers of such material and
        are protected by United States and international copyright, trademark,
        patent, trade secret and other intellectual property or proprietary
        rights laws. These Terms of Use permit you to use the Site for your
        personal, non-commercial use only. You must not reproduce, distribute,
        modify, create derivative works of, publicly display, publicly perform,
        republish, download, store or transmit any of the material on our Site
        except as generally and ordinarily permitted through the Site according
        to these Terms of Use. You must not access or use for any commercial
        purposes any part of the Site or any services or materials available
        through the Site.
      </p>

      <h2>Trademarks.</h2>
      <p>
        Certain of the names, logos, and other materials displayed on the Site
        or in the Services may constitute trademarks, trade names, service marks
        or logos ("<strong>Marks</strong>") of SwyftDoc or other entities. You
        are not authorized to use any such Marks without the express written
        permission of SwyftDoc. Ownership of all such Marks and the goodwill
        associated therewith remains with us or those other entities.{" "}
      </p>

      <h2>Privacy and Communications.</h2>
      <p>
        SwyftDoc is required to comply with federal healthcare privacy and
        security laws and maintain safeguards to protect the security of your
        health information. Additionally, the information you provide to your
        Healthcare Professional during a medical consultation or therapy session
        is legally confidential, except for certain legal exceptions as more
        fully described in our Privacy Policy. We devote considerable effort
        toward ensuring that your personal information is secure. Information
        regarding our use of health and other personal information is provided
        in our Privacy Policy.{" "}
      </p>
      <p>
        As part of providing you with the Services, we may need to provide you
        with certain communications such as appointment reminders, service
        announcements, privacy notices, administrative messages and other
        communications about the Services (“<strong>Communications</strong>”).
        These Communications are considered part of the Services and your
        Account.{" "}
      </p>
      <p>
        You understand that by checking the “agree” box for these Terms of Use
        and/or any other forms presented to you on the Site you are agreeing to
        these Terms of Use and that such action constitutes a legal signature.
        You agree that we may send to you Communications through electronic
        means including but not limited to: (1) by email, using the address that
        you provided to us during registration, (2) short messaging service (“
        <strong>SMS</strong>”) text message to the mobile number you provided us
        during registration (“<strong>SMS Messages</strong>”), (3) push
        notifications on your tablet or mobile device, or (4) by posting
        Communications on the Site. The delivery of any Communications from us
        is effective when sent by us, regardless of whether you read the
        Communication. You can withdraw your consent to receive Communications
        by deactivating your Account. While secure electronic messaging is
        always preferred to insecure email, under certain circumstances,
        insecure email communication containing personal health information may
        take place between you and SwyftDoc. SwyftDoc cannot ensure the security
        or confidentiality of messages sent by email. Information relating to
        your care, including clinical notes and medical records, are stored on
        secure, encrypted servers maintained by SwyftDoc.{" "}
      </p>

      <a href="#sms" id="sms">
        <h3>SMS Messages Terms and Conditions</h3>
      </a>
      <p>
        The number of SMS Messages we send as part of our Communications will
        depend on the frequency of your use of the Services. You can stop
        receiving SMS Messages at any time by texting "<strong>STOP</strong>" to
        91892. If you send the message "<strong>STOP</strong>" to us, we will
        send you a reply message to confirm that you have been unsubscribed from
        SMS Messages. After this, you will no longer receive SMS Messages from
        us. If you text "<strong>HELP</strong>" to us, we will reply with
        instructions on how to receive SMS Messages as well as how to
        unsubscribe from SMS Messages.
      </p>

      <h3>Participating Carriers</h3>
      <p>
        AT&T, Verizon Wireless, Sprint, T-Mobile, U.S. Cellular, Boost Mobile,
        MetroPCS, Virgin Mobile, Alaska Communications Systems (ACS),
        Appalachian Wireless (EKN), Bluegrass Cellular, Cellular One of East
        Central, IL (ECIT), Cellular One of Northeast Pennsylvania, Cricket,
        Coral Wireless (Mobi PCS), COX, Cross, Element Mobile (Flat Wireless),
        Epic Touch (Elkhart Telephone), GCI, Golden State, Hawkeye (Chat
        Mobility), Hawkeye (NW Missouri), Illinois Valley Cellular, Inland
        Cellular, iWireless (Iowa Wireless), Keystone Wireless (Immix
        Wireless/PC Man), Mosaic (Consolidated or CTC Telecom), Nex-Tech
        Wireless, NTelos, Panhandle Communications, Pioneer, Plateau (Texas RSA
        3 Ltd), Revol, RINA, Simmetry (TMP Corporation), Thumb Cellular, Union
        Wireless, United Wireless, Viaero Wireless, and West Central (WCC or 5
        Star Wireless).
      </p>
      <p>T-Mobile is not liable for delayed or undelivered messages.</p>
      <p>
        Message and data rates may apply for any messages sent to you from us
        and to us from you. If you have any questions about your text plan or
        data plan, it is best to contact your wireless provider.
      </p>

      <h2>Miscellaneous.</h2>
      <p>
        These Terms of Use and your use of the Site shall be governed by the
        laws of the State of Delaware, without giving effect to the principles
        of conflict of laws. ANY DISPUTE ARISING UNDER OR RELATING IN ANY WAY TO
        THESE TERMS OF USE WILL BE RESOLVED EXCLUSIVELY BY FINAL AND BINDING
        ARBITRATION IN SAN FRANCISCO, CALIFORNIA UNDER THE RULES OF THE AMERICAN
        ARBITRATION ASSOCIATION, EXCEPT THAT EITHER PARTY MAY BRING A CLAIM
        RELATED TO INTELLECTUAL PROPERTY RIGHTS, OR SEEK TEMPORARY AND
        PRELIMINARY SPECIFIC PERFORMANCE AND INJUNCTIVE RELIEF, IN ANY COURT OF
        COMPETENT JURISDICTION, WITHOUT THE POSTING OF BOND OR OTHER SECURITY.
        ALL CLAIMS, WHETHER IN ARBITRATION OR OTHERWISE, MUST BE BROUGHT SOLELY
        IN YOUR INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN
        ANY PURPORTED CLASS OR COLLECTIVE PROCEEDING. The parties agree to the
        personal and subject matter jurisdiction and venue of the courts located
        in San Francisco, California, for any action related to these Terms of
        Use.{" "}
      </p>
      <p>
        No waiver by SwyftDoc of any term or condition set forth in these Terms
        of Use shall be deemed a further or continuing waiver of such term or
        condition or a waiver of any other term or condition, and any failure of
        SwyftDoc to assert a right or provision under these Terms of Use shall
        not constitute a waiver of such right or provision. If any provision of
        these Terms of Use is held by a court or other tribunal of competent
        jurisdiction to be invalid, illegal or unenforceable for any reason,
        such provision shall be eliminated or limited to the minimum extent such
        that the remaining provisions of the Terms of Use will continue in full
        force and effect.
      </p>
      <p>
        SwyftDoc devotes considerable effort to optimizing signal strength and
        diagnosis deficiencies but is not responsible for the internet or data
        bandwidth and signal of your mobile device.
      </p>
      <p>
        The Digital Millennium Copyright Act of 1998 (the "<strong>DMCA</strong>
        ") provides recourse for copyright owners who believe that material
        appearing on the Internet infringes their rights under U.S. copyright
        law. If you believe in good faith that materials appearing on the Site
        infringe your copyright, you (or your agent) may send us a notice
        requesting that the material be removed, or access to it blocked. In
        addition, if you believe in good faith that a notice of copyright
        infringement has been wrongly filed against you, the DMCA permits you to
        send us a counter-notice. Notices and counter-notices must meet
        statutory requirements imposed by the DMCA. One place to find more
        information is the U.S. Copyright Office Website, currently located at{" "}
        <a href="http://www.loc.gov/copyright" target="_blank">
          http://www.loc.gov/copyright
        </a>
        . In accordance with the DMCA, SwyftDoc has designated an agent to
        receive notification of alleged copyright infringement in accordance
        with the DMCA. Any written Notification of Claimed infringement should
        comply with Title 17, United States Code, Section 512(c)(3)(A) and
        should be provided in writing to Swyft Health, LLC., 10345 Crosscreek
        Terrace, San Diego, CA 92131
      </p>
      <p>
        Please send any questions or report any violations of these Terms of Use
        to{" "}
        <a href="mailto:support@swyftdoc.com" target="_blank">
          support@swyftdoc.com.
        </a>
      </p>
    </div>
  );
}
